import { setup, assign, fromPromise } from "xstate";
import {
  Member,
  Membership,
  getMemberships,
  MembershipType,
  getMembershipDependents,
  submitMembershipDependents,
  updateMembershipDependents,
  MembershipDependent,
  MembershipDependentStatus,
} from "../../services/core-api-adapter";

interface Context {
  getDependentsResponse: MembershipDependent[] | [];
  activeDependent: MembershipDependent | null;
  getMembershipsResponse: Membership[] | null;
  submittingDependentDetailsError: any;
  maxAllowedDependents: number;
  maxDependentAge: number;
  mainMember: Member | null;
}

const initialContextValues: Context = {
  getDependentsResponse: [],
  activeDependent: null,
  getMembershipsResponse: null,
  submittingDependentDetailsError: null,
  maxAllowedDependents: 3,
  maxDependentAge: 18,
  mainMember: null,
};

export const eventNames = {
  ADD_PEOPLE_BUTTON_CLICKED: "ADD_PEOPLE_BUTTON_CLICKED",
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  CLOSE_BUTTON_CLICKED: "CLOSE_BUTTON_CLICKED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  ADD_DEPENDENT_BUTTON_CLICKED: "ADD_DEPENDENT_BUTTON_CLICKED",
  EDIT_DEPENDENT_BUTTON_CLICKED: "EDIT_DEPENDENT_BUTTON_CLICKED",
  DELETE_DEPENDENT_BUTTON_CLICKED: "DELETE_DEPENDENT_BUTTON_CLICKED",
  CONFIRM_DELETE_DEPENDENT_BUTTON_CLICKED:
    "CONFIRM_DELETE_DEPENDENT_BUTTON_CLICKED",
  SHOW_SUMMARY_SCREEN: "SHOW_SUMMARY_SCREEN",
};

export const membershipAddDependentsFlowStateMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addMembershipsResponseToContext: assign({
      getMembershipsResponse: ({ event }) => {
        return event.output;
      },
    }),
    addMainMembershipResponseToContext: assign({
      mainMember: ({ event }) => {
        const memberships = event.output?.memberships[0]?.members;

        const getMainMember = (memberships || []).filter(
          (member: any) =>
            member.membershipDetails?.type === MembershipType.MAIN_MEMBER
        );

        return getMainMember[0];
      },
    }),
    addDependentDetailsToContext: assign({
      activeDependent: ({ event }) => {
        return event.input;
      },
    }),
    addDependentsResponseToContext: assign({
      getDependentsResponse: ({ event }) => {
        return event.output;
      },
    }),
    updateDependentDetailsInContext: assign({
      activeDependent: ({ event }) => {
        return { ...event.input, status: MembershipDependentStatus.ACTIVE };
      },
    }),
    removeDependentDetailsInContext: assign({
      activeDependent: () => {
        return null;
      },
    }),
    addSubmittingDependentDetailsErrorsToContext: assign({
      submittingDependentDetailsError: ({ event }) => {
        return event.output;
      },
    }),
  },
  actors: {
    getMemberships: fromPromise(async () => {
      return await getMemberships();
    }),
    fetchingDependents: fromPromise(async () => {
      return await getMembershipDependents();
    }),
    submitDependentDetails: fromPromise(
      async ({
        input,
      }: {
        input: { activeDependent: MembershipDependent | null };
      }) => {
        return await submitMembershipDependents({
          dependents: input?.activeDependent ? [input.activeDependent] : [],
        });
      }
    ),
    updateMembershipDependents: fromPromise(
      async ({
        input,
      }: {
        input: { activeDependent: MembershipDependent | null };
      }) => {
        return await updateMembershipDependents({
          dependents: input?.activeDependent ? [input.activeDependent] : [],
        });
      }
    ),
    deleteDependent: fromPromise(
      async ({
        input,
      }: {
        input: { activeDependent: MembershipDependent | null };
      }) => {
        const activeDependent = {
          ...input?.activeDependent,
          status: MembershipDependentStatus.DELETE,
        };

        return await updateMembershipDependents({
          dependents: (activeDependent ? [activeDependent] : []) as any,
        });
      }
    ),
  },
}).createMachine({
  context: initialContextValues,
  id: "membershipAddDependentsFlow",
  initial: "start",
  states: {
    start: {
      on: {
        SHOW_SUMMARY_SCREEN: {
          target: "fetchingDependents",
        },
      },
      entry: assign({ ...initialContextValues }),
      invoke: {
        id: "start",
        input: {},
        onDone: {
          target: "collectingDependentDetails",
          actions: [
            "addMembershipsResponseToContext",
            "addMainMembershipResponseToContext",
          ],
        },
        onError: {
          target: "error",
        },
        src: "getMemberships",
      },
    },
    fetchingDependents: {
      invoke: {
        id: "fetchingDependents",
        input: {},
        onDone: {
          target: "fetchAndAddMainMemberToContextAndShowSummary",
          actions: {
            type: "addDependentsResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "fetchingDependents",
      },
    },
    collectingDependentDetails: {
      on: {
        CONTINUE_BUTTON_CLICKED: {
          target: "submittingDependentDetails",
          actions: {
            type: "addDependentDetailsToContext",
          },
        },
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    error: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    fetchAndAddMainMemberToContextAndShowSummary: {
      invoke: {
        id: "fetchAndAddMainMemberToContextAndShowSummary",
        input: {},
        onDone: {
          target: "dependentsSummary",
          actions: [
            {
              type: "addMembershipsResponseToContext",
            },
            {
              type: "addMainMembershipResponseToContext",
            },
          ],
        },
        onError: {
          target: "error",
        },
        src: "getMemberships",
      },
    },
    submittingDependentDetails: {
      invoke: {
        id: "submittingDependentDetails",
        src: "submitDependentDetails",
        input: ({ context }) => {
          return { activeDependent: context.activeDependent };
        },
        onDone: {
          target: "fetchingDependents",
        },
        onError: {
          target: "error",
        },
      },
    },
    exit: {
      type: "final",
    },
    dependentsSummary: {
      on: {
        CONTINUE_BUTTON_CLICKED: {
          target: "thankYou",
        },
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
        ADD_PEOPLE_BUTTON_CLICKED: {
          target: "collectingDependentDetails",
          actions: {
            type: "removeDependentDetailsInContext",
          },
        },
        EDIT_DEPENDENT_BUTTON_CLICKED: {
          target: "editingDependentDetails",
          actions: {
            type: "updateDependentDetailsInContext",
          },
        },
        DELETE_DEPENDENT_BUTTON_CLICKED: {
          target: "confirmingDependentRemoval",
          actions: {
            type: "updateDependentDetailsInContext",
          },
        },
        ADD_DEPENDENT_BUTTON_CLICKED: {
          target: "collectingDependentDetails",
        },
      },
    },
    thankYou: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    editingDependentDetails: {
      on: {
        CONTINUE_BUTTON_CLICKED: {
          target: "updatingMembershipDependents",
          actions: {
            type: "updateDependentDetailsInContext",
          },
        },
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    confirmingDependentRemoval: {
      on: {
        CONFIRM_DELETE_DEPENDENT_BUTTON_CLICKED: {
          target: "deletingDependentDetails",
        },
        BACK_BUTTON_CLICKED: {
          target: "fetchingDependents",
        },
      },
    },
    updatingMembershipDependents: {
      invoke: {
        id: "updatingMembershipDependents",
        input: ({ context }) => {
          return { activeDependent: context.activeDependent };
        },
        onDone: {
          target: "fetchingDependents",
        },
        onError: {
          target: "collectingDependentDetails",
          actions: {
            type: "addSubmittingDependentDetailsErrorsToContext",
          },
        },
        src: "updateMembershipDependents",
      },
    },
    deletingDependentDetails: {
      invoke: {
        id: "deletingDependentDetails",
        src: "deleteDependent",
        input: ({ context }) => {
          return { activeDependent: context.activeDependent };
        },
        onDone: {
          target: "deleteDependentSuccess",
        },
        onError: {
          target: "deleteDependentError",
        },
      },
    },
    deleteDependentSuccess: {
      on: {
        CLOSE_BUTTON_CLICKED: {
          target: "fetchingDependents",
        },
      },
    },
    deleteDependentError: {
      on: {
        CLOSE_BUTTON_CLICKED: {
          target: "fetchingDependents",
        },
      },
    },
    dependentsAddedSuccessfully: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
  },
});
