import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  IconButton,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  IdentityDocumentType,
  Member,
  MembershipDependent,
} from "../../../../services/core-api-adapter";
import Panel from "../../../Panel";
import IconLoader from "../../../IconLoader";
import FaceIconBrandLarge from "../../../../theme/icons/FaceIconBrandLarge";
import CircledBackButton from "../../../CircledBackButton";
import { FaceIconBrand } from "../../../../theme/icons";
import { replaceSubjectWithString, convertPxToRem } from "../../../../utils";
import MembershipStatusIndicator from "../../../MembershipStatusIndicator";

interface DependentsOverviewProps {
  dependentsList: MembershipDependent[];
  mainMember: Member;
  remainingDependentsCount: number;
  maxDependentAge: number;
  onDone: () => void;
  onEditDependent: (dependent: MembershipDependent) => void;
  onRemoveDependent: (dependent: MembershipDependent) => void;
  onAddMoreDependentButtonClick: () => void;
}
const MIN_ALLOWED_DEPENDENTS = 1;

export default function DependentsOverview({
  dependentsList,
  mainMember,
  remainingDependentsCount,
  maxDependentAge,
  onDone,
  onEditDependent,
  onRemoveDependent,
  onAddMoreDependentButtonClick,
}: DependentsOverviewProps) {
  const { t } = useTranslation();

  function onEditDependentHandler(dependent: MembershipDependent) {
    onEditDependent(dependent);
  }

  function onRemoveDependentHandler(dependent: MembershipDependent) {
    onRemoveDependent(dependent);
  }

  function onAddDependentButtonHandler() {
    onAddMoreDependentButtonClick();
  }

  function onDoneButtonHandler() {
    onDone();
  }

  return (
    <Stack justifyContent="space-between" spacing={4} p={2}>
      <CircledBackButton showLabel={true} />

      <Stack py={4} spacing={2}>
        <FaceIconBrandLarge />
        <Typography component="h1" variant="h3">
          {t("AddDependents.summary.title")}
        </Typography>
      </Stack>

      <Panel>
        {mainMember?.memberDetails?.memberFullName && (
          <Stack justifyContent="space-between" flexDirection="row">
            <Stack>
              <Typography
                component="h2"
                variant="h4"
                fontWeight="600"
                sx={{ wordBreak: "break-word" }}
              >
                {mainMember?.memberDetails?.memberFullName}
              </Typography>
              <Typography
                color="neutral.500"
                fontSize={convertPxToRem(12)}
                variant="h2"
                pt={0.5}
              >
                {t("AddDependents.summary.mainMemberLabel")}
              </Typography>
            </Stack>

            <Stack>
              <MembershipStatusIndicator
                overrideStyles={{
                  border: "none",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                }}
                status={mainMember?.membershipDetails.status}
              />
            </Stack>
          </Stack>
        )}

        <Divider sx={{ my: 2 }} />

        <Stack spacing={4}>
          {Array.isArray(dependentsList) && (
            <Stack
              component="ul"
              divider={<Divider />}
              aria-label={t(
                "MembershipManagementDependentsList.dependentsList.label"
              )}
            >
              {(dependentsList || []).map((dependent, index) => {
                const fullName = `${dependent?.firstName} ${dependent?.lastName}`;
                const listItemKey = `${fullName}_${index}`;
                const identityDocumentValue =
                  dependent?.identityDocumentValue || "";
                const identityDocumentType = dependent?.identityDocumentType
                  ? replaceSubjectWithString(
                      dependent?.identityDocumentType ||
                        IdentityDocumentType.IdNumber,
                      IdentityDocumentType.UNUID,
                      "UNUID"
                    )
                  : null;

                return (
                  <ListItem
                    key={listItemKey}
                    aria-label={fullName}
                    sx={{ px: 0, py: 2 }}
                  >
                    <Stack spacing={2} direction="row" width="100%">
                      <FaceIconBrand
                        sx={{ fontSize: (theme) => theme.spacing(4) }}
                      />

                      <Stack spacing={2} width="100%">
                        <Stack spacing={1}>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignContent="flex-start"
                            justifyContent="space-between"
                          >
                            <Typography
                              component="p"
                              variant="h4"
                              fontWeight="600"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {fullName}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={1}
                          width="100%"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Stack>
                            {identityDocumentType && (
                              <Typography variant="body2" color="GrayText">
                                {t(
                                  `MembershipDependentsDetailPanel.dependentsList.item.${identityDocumentType}Label` as any
                                )}
                              </Typography>
                            )}
                            {identityDocumentValue && (
                              <Typography sx={{ wordBreak: "break-word" }}>
                                {identityDocumentValue}
                              </Typography>
                            )}
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="flex-end"
                          >
                            <IconButton
                              onClick={() => onEditDependentHandler(dependent)}
                              color="primary"
                              aria-label={t("common.editButton")}
                            >
                              <IconLoader icon="PenIcon" />
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                onRemoveDependentHandler(dependent)
                              }
                              color="primary"
                              aria-label={t("common.removeButton")}
                            >
                              <IconLoader icon="BinIcon" />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </ListItem>
                );
              })}
            </Stack>
          )}
          <>
            {dependentsList && dependentsList.length > 0 && (
              <Divider sx={{ mx: 2 }} />
            )}
            <Button
              variant="outlined"
              size="small"
              fullWidth
              disabled={remainingDependentsCount < MIN_ALLOWED_DEPENDENTS}
              onClick={onAddDependentButtonHandler}
            >
              {t("AddDependents.summary.addPeopleButtonLabel")}
            </Button>

            {remainingDependentsCount >= MIN_ALLOWED_DEPENDENTS && (
              <Typography
                color="neutral.500"
                textAlign="center"
                variant="body2"
              >
                <Trans
                  i18nKey="AddDependents.summary.remainingDependentsLabel"
                  values={{
                    count: remainingDependentsCount,
                    maxDependentAge: maxDependentAge,
                  }}
                />
              </Typography>
            )}
          </>
        </Stack>
      </Panel>
      <Button size="small" fullWidth onClick={onDoneButtonHandler}>
        {t("common.done")}
      </Button>
    </Stack>
  );
}
