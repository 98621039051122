import { Stack, Box, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MembershipDependent } from "../../../../services/core-api-adapter";
import IconLoader from "../../../IconLoader";
import LoadingSpinner from "../../../LoadingSpinner";
import SlideUpDialog from "../../../SlideUpDialog";

interface ConfirmDependentRemovalProps {
  dependent: MembershipDependent;
  confirmingRemoval: boolean;
  attemptingRemoval: boolean;
  removalError: boolean;
  removalSuccess: boolean;
  shouldModalOpen: boolean;
  onDependentRemoval: () => void;
  onModalClose: () => void;
}

export function ConfirmDependentRemoval({
  dependent,
  confirmingRemoval,
  attemptingRemoval,
  removalError,
  removalSuccess,
  shouldModalOpen,
  onDependentRemoval,
  onModalClose,
}: ConfirmDependentRemovalProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(confirmingRemoval);
  const { t } = useTranslation();

  function onCancelRemoveDependent() {
    onModalClose();
  }

  function onConfirmRemoveDependent() {
    onDependentRemoval();
  }

  useEffect(() => {
    setIsModalOpen(shouldModalOpen);
  }, [shouldModalOpen, confirmingRemoval, attemptingRemoval]);

  return (
    <SlideUpDialog
      isOpen={shouldModalOpen || isModalOpen}
      onClose={() => onModalClose()}
      label={t("MembershipManagementRemoveDependentForm.title")}
    >
      <Stack component="article" spacing={2}>
        {!removalSuccess && !removalError && confirmingRemoval && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="FaceWithCrossIcon"
                color="error"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("AddDependents.confirmRemoval.title", {
                fullName: `${dependent?.firstName} ${dependent?.lastName}`,
              })}
            </Typography>

            {(
              t<any, any, string[]>("AddDependents.confirmRemoval.body", {
                fullName: `${dependent?.firstName} ${dependent?.lastName}`,
                returnObjects: true,
              }) as []
            ).map((text: string) => (
              <Typography key={text} textAlign="center">
                <Trans>{text}</Trans>
              </Typography>
            ))}

            <Stack component="footer" spacing={1}>
              <Button onClick={onConfirmRemoveDependent} size="small" fullWidth>
                {t("AddDependents.successfulRemoval.confirmButtonLabel")}
              </Button>

              <Button
                size="small"
                variant="text"
                fullWidth
                onClick={onCancelRemoveDependent}
              >
                {t("common.cancelButton")}
              </Button>
            </Stack>
          </>
        )}

        {!removalSuccess && !removalError && attemptingRemoval && (
          <Box textAlign="center">
            <LoadingSpinner />
          </Box>
        )}

        {removalError && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="FaceWithCrossIcon"
                color="error"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>
            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("AddDependents.errorInRemoval.title")}
            </Typography>

            <Typography textAlign="center">
              {t("AddDependents.errorInRemoval.body")}
            </Typography>
            <Button size="small" fullWidth onClick={onCancelRemoveDependent}>
              {t("common.close")}
            </Button>
          </>
        )}

        {removalSuccess && (
          <>
            <Box textAlign="center">
              <IconLoader
                icon="SpeechBubbleWithRingingPhoneIcon"
                color="primary"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("AddDependents.successfulRemoval.title", {
                fullName: dependent?.firstName,
              })}
            </Typography>

            {(
              t<any, any, string[]>("AddDependents.successfulRemoval.body", {
                fullName: `${dependent?.firstName} ${dependent?.lastName}`,
                returnObjects: true,
              }) as []
            ).map((text: string) => (
              <Typography key={text} textAlign="center">
                <Trans>{text}</Trans>
              </Typography>
            ))}

            <Stack component="footer">
              <Button size="small" fullWidth onClick={onCancelRemoveDependent}>
                {t("common.close")}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </SlideUpDialog>
  );
}
