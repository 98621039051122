import {
  Autocomplete,
  Box,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../theme";
import { convertPxToRem } from "../../utils";
import { PassportCountryCode, countries } from "../../utils/countries";

export interface Props {
  onCountryCodeChange: (selectedCountry: PassportCountryCode | null) => void;
  value?: string;
  label?: string;
  showLabel?: boolean;
}

function CustomPaperComponent({ props }: any) {
  return (
    <Paper
      sx={{
        border: 1,
        borderColor: "neutral.300",
        "&:hover": {
          borderColor: "primary.main",
        },
      }}
      {...props}
    />
  );
}
export default function PassportCountryOfIssueCode({
  value,
  label,
  showLabel = true,
  onCountryCodeChange,
}: Props) {
  const { t } = useTranslation();
  const inputLabel = label || t("common.passportCountryCode");

  const [selectedCountry, setSelectedCountry] =
    useState<PassportCountryCode | null>(null);

  useMemo(() => {
    onCountryCodeChange(selectedCountry);
  }, [selectedCountry]);

  const handleCountryChange = (
    event: React.ChangeEvent<{}>,
    value: PassportCountryCode | null
  ) => {
    setSelectedCountry(value);
  };

  const featuredCountries = ["ZA", "MZ", "UG"];

  function filterFeaturedCountries() {
    const featured: PassportCountryCode[] = [];
    const nonFeatured: PassportCountryCode[] = [];

    countries.forEach((country) => {
      if (featuredCountries.includes(country.alpha2)) {
        featured.push(country);
      } else {
        nonFeatured.push(country);
      }
    });

    const sortedFeaturedCountries = featured.sort((a, b) =>
      a.countryName.localeCompare(b.countryName)
    );

    const sortedNonFeaturedCountries = nonFeatured.sort((a, b) =>
      a.countryName.localeCompare(b.countryName)
    );

    return [
      {
        countries: sortedFeaturedCountries,
      },
      {
        countries: sortedNonFeaturedCountries,
      },
    ].flatMap((group) => group.countries);
  }

  const filteredCountries = filterFeaturedCountries();

  useEffect(() => {
    if (value) {
      const foundCountryByCountryCode = countries.find(
        (country: PassportCountryCode) =>
          country.alpha2 === value || country.alpha3 === value
      );

      if (foundCountryByCountryCode) {
        setSelectedCountry(foundCountryByCountryCode);
      }
    }
  }, [value]);
  return (
    <Stack spacing={1}>
      {showLabel && (
        <InputLabel htmlFor={inputLabel}>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {inputLabel}
          </Typography>
        </InputLabel>
      )}
      <Autocomplete
        id={inputLabel}
        options={filteredCountries}
        fullWidth
        value={selectedCountry}
        onChange={handleCountryChange}
        aria-label={t("common.passportCountryCodeAriaLabel")}
        groupBy={(option) =>
          option.alpha2 && featuredCountries.includes(option.alpha2)
            ? ""
            : "All countries"
        }
        getOptionLabel={(option) => option.countryName}
        renderGroup={(params) => (
          <Stack key={params.key}>
            {params.group !== "" && (
              <Typography
                variant="body2"
                color="neutral.700"
                sx={{
                  padding: `${convertPxToRem(8)} ${convertPxToRem(16)}`,
                  marginBottom: 1,
                  borderBottom: 1,
                  borderColor: "neutral.300",
                  letterSpacing: 2,
                  textTransform: "uppercase",
                }}
              >
                {params.group}
              </Typography>
            )}

            <Stack>{params.children}</Stack>
          </Stack>
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            value={option.alpha3}
            {...props}
            justifyContent="space-between !important"
          >
            <Stack display="flex" flexDirection="row" gap={1}>
              <img
                loading="lazy"
                width={convertPxToRem(275)}
                src={`/countries/${option.alpha2.toUpperCase()}.svg`}
                alt={option.countryName}
              />
              <Typography>{option.countryName}</Typography>
            </Stack>
            <Typography>
              {option.alpha3 ? option.alpha3 : option.alpha2}
            </Typography>
          </Box>
        )}
        PaperComponent={(props) => <CustomPaperComponent props={props} />}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            placeholder={t("common.searchAndSelect")}
            sx={{
              input: {
                ...styleInputDefault,
                fontSize: `${convertPxToRem(14)} !important`,
              },
            }}
          />
        )}
      />
    </Stack>
  );
}
