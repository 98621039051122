import { Stack, Typography } from "@mui/material";
import { useMachine } from "@xstate/react";
import { useEffect, useState } from "react";
import AddDependentsThankYou from "./components/AddDependentsThankYou";
import DependentsOverview from "./components/DependentsOverview";
import MembershipAddDependentsForm from "./components/MembershipAddDependentsForm";
import { ConfirmDependentRemoval } from "./components/ConfirmDependentRemoval";
import { eventNames, membershipAddDependentsFlowStateMachine } from "./machine";
import { MembershipDependent } from "../../services/core-api-adapter";
import { useLocation, Navigate } from "react-router";
import DefaultError from "../DefaultError";
import { useTranslation } from "react-i18next";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";

export default function MembershipAddDependentsFlow() {
  const { t } = useTranslation();
  const location = useLocation();

  const [isDependentRemovalModalOpen, setIsDependentRemovalModalOpen] =
    useState<boolean>(false);
  const [isAttemptingRemoval, setIsAttemptingRemoval] =
    useState<boolean>(false);

  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    membershipAddDependentsFlowStateMachine
  );

  function onAddDependentsCompletedFlow(dependent: MembershipDependent) {
    dispatchStateMachineEvent({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      input: dependent,
    });
  }

  function calculateRemainingAllowedDependents() {
    const addedDependents = stateMachineState.context?.getDependentsResponse;

    if (addedDependents && Array.isArray(addedDependents)) {
      return (
        stateMachineState.context?.maxAllowedDependents -
        addedDependents?.length
      );
    }

    return stateMachineState.context?.maxAllowedDependents;
  }

  function onThankYouPageDone() {
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function onDependentsOverviewDone() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onDependentsOverviewEdit(dependent: MembershipDependent) {
    dispatchStateMachineEvent({
      type: eventNames.EDIT_DEPENDENT_BUTTON_CLICKED,
      input: dependent,
    });
  }

  function onDependentsOverviewRemove(dependent: MembershipDependent) {
    setIsDependentRemovalModalOpen(true);
    dispatchStateMachineEvent({
      type: eventNames.DELETE_DEPENDENT_BUTTON_CLICKED,
      input: dependent,
    });
  }

  function onDependentsOverviewConfirmDependentRemoval() {
    setIsAttemptingRemoval(true);
    dispatchStateMachineEvent({
      type: eventNames.CONFIRM_DELETE_DEPENDENT_BUTTON_CLICKED,
      input: stateMachineState.context?.activeDependent,
    });
  }

  function onDependentsOverviewAddMoreDependentButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.ADD_PEOPLE_BUTTON_CLICKED });
  }

  function onGoHomeButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function onModalClose() {
    dispatchStateMachineEvent({ type: eventNames.CLOSE_BUTTON_CLICKED });
    setIsDependentRemovalModalOpen(false);
  }

  useEffect(() => {
    setIsAttemptingRemoval(
      stateMachineState.matches("deletingDependentDetails")
    );

    const shouldImmediatelyShowSummaryScreen =
      location.search.indexOf("showSummaryScreen");

    if (shouldImmediatelyShowSummaryScreen > -1) {
      dispatchStateMachineEvent({ type: eventNames.SHOW_SUMMARY_SCREEN });
    }
  }, [
    isAttemptingRemoval,
    eventNames,
    stateMachineState,
    stateMachineState.context,
  ]);

  function findExistingDependent() {
    const dependents = stateMachineState.context?.getDependentsResponse;
    const activeDependent = stateMachineState.context?.activeDependent;

    const foundDependent =
      dependents?.find(
        (dep: any) =>
          dep?.identityDocumentValue === activeDependent?.identityDocumentValue
      ) || null;

    return foundDependent;
  }

  return (
    <Stack height="100%" p={2}>
      {stateMachineState.matches("fetchingDependents") && (
        <FullscreenLoadingIndicator />
      )}

      {(stateMachineState.matches("collectingDependentDetails") ||
        stateMachineState.matches("editingDependentDetails")) && (
        <MembershipAddDependentsForm
          selectedDependent={
            findExistingDependent() || stateMachineState.context.activeDependent
          }
          onCompletedFlow={onAddDependentsCompletedFlow}
        />
      )}

      {(stateMachineState.matches("dependentsSummary") ||
        stateMachineState.matches(
          "fetchAndAddMainMemberToContextAndShowSummary"
        ) ||
        stateMachineState.matches("confirmingDependentRemoval") ||
        stateMachineState.matches("deleteDependentError") ||
        stateMachineState.matches("deleteDependentSuccess") ||
        stateMachineState.matches("deletingDependentDetails")) && (
        <DependentsOverview
          onDone={onDependentsOverviewDone}
          onEditDependent={onDependentsOverviewEdit}
          onRemoveDependent={onDependentsOverviewRemove}
          onAddMoreDependentButtonClick={
            onDependentsOverviewAddMoreDependentButtonClick
          }
          dependentsList={
            stateMachineState.context.getDependentsResponse as any
          }
          remainingDependentsCount={calculateRemainingAllowedDependents()}
          maxDependentAge={stateMachineState.context.maxDependentAge}
          mainMember={stateMachineState.context.mainMember as any}
        />
      )}

      {stateMachineState.matches("thankYou") && (
        <AddDependentsThankYou onDone={onThankYouPageDone} />
      )}

      {stateMachineState.matches("error") && (
        <Stack height="100vh" justifyContent="end">
          <DefaultError onComeBackLater={onGoHomeButtonClick}>
            <Typography variant="h2">{t("GenericError.title")}</Typography>
            <Typography pb={2}>{t("GenericError.content")}</Typography>
          </DefaultError>
        </Stack>
      )}

      {(stateMachineState.matches("confirmingDependentRemoval") ||
        stateMachineState.matches("deleteDependentError") ||
        stateMachineState.matches("deleteDependentSuccess") ||
        stateMachineState.matches("deletingDependentDetails")) && (
        <ConfirmDependentRemoval
          dependent={stateMachineState.context.activeDependent as any}
          onDependentRemoval={onDependentsOverviewConfirmDependentRemoval}
          confirmingRemoval={
            isDependentRemovalModalOpen && !isAttemptingRemoval
          }
          attemptingRemoval={isAttemptingRemoval}
          removalError={stateMachineState.matches("deleteDependentError")}
          removalSuccess={stateMachineState.matches("deleteDependentSuccess")}
          shouldModalOpen={isDependentRemovalModalOpen}
          onModalClose={onModalClose}
        />
      )}

      {stateMachineState.matches("exit") && <Navigate to="/"></Navigate>}
    </Stack>
  );
}
